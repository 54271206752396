import {StaticImage} from 'gatsby-plugin-image'
import React from 'react'
import {Container} from 'react-bootstrap'

import Section from '../../Section'
import ShareButton from '../../ShareButton'

import {link} from './Footer.module.scss'

export interface Certificate {
  jobCode: string
  dateOfPrep: string
}
interface Props {
  certificate: Certificate
}

const Footer: React.FC<Props> = ({certificate}) => (
  <footer className="footer">
    <Section>
      <Container>
        <div className="d-flex flex-wrap justify-content-between gap-4">
          <StaticImage src="./logo-color.png" alt="MSD logo" width={150} />
          <ShareButton
            text="Share this page"
            url="https://www.chickenpoxaware.co.uk/"
          />
        </div>
        <a
          className="btn btn-teal my-4 my-sm-5 px-3 text-white rounded-pill fs-5 fw-semibold"
          href="https://www.msd-uk.com/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          Contact us
        </a>
        <p className="text-teal">
          <a
            className={link}
            href="https://www.msdprivacy.com/uk/en/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </a>
          {' | '}
          <a
            className={link}
            href="http://www.msdproduct.co.uk/terms_of_use/TermsofUse.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          {' | '}
          <button className={`ot-sdk-show-settings ${link}`}>
            Cookie Settings
          </button>
          {' | '}
          <a
            className={link}
            href="https://www.essentialaccessibility.com/msd"
            target="_blank"
            rel="noreferrer"
          >
            Accessibility
          </a>
        </p>
        <StaticImage
          className="mb-5"
          src="./accessibilityIcon.png"
          alt="accessibility icon"
        />
        <h6 className="fw-semibold">Reporting of side effects:</h6>
        <p>
          If you get any side effects with any medicines, talk to your doctor,
          pharmacist or nurse. This includes any possible side effects not
          listed in the medicines package leaflet. You can also report side
          effects directly via the Yellow Card Scheme at{' '}
          <a
            className={link}
            href="https://yellowcard.mhra.gov.uk"
            target="_blank"
            rel="noreferrer"
          >
            https://yellowcard.mhra.gov.uk
          </a>{' '}
          or by searching for MHRA Yellow Card in the Google Play or Apple App
          Store.
        </p>
        <p>
          By reporting side effects you can help provide more information on the
          safety of medicines.
          <br />
          By clicking the above link you will leave the MSD website and be taken
          to the MHRA website.
        </p>
        <p>
          {certificate.dateOfPrep}
          <br />
          {certificate.jobCode}
          <br />
          Merck Sharp & Dohme (UK) Limited
          <br />
          Registered Office: 120 Moorgate, London, EC2M 6UR, United Kingdom
        </p>
        <p>
          Registered in England No. 233687
          <br />
          Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its
          affiliates. All rights reserved.
        </p>
      </Container>
    </Section>
  </footer>
)
export default Footer
